<template>
  <div>
    <h4>December 16 2010 (v.1.2.25b)</h4>
    <p>
      <u>Pet Taming</u>
      <br>- Some of you might have found it a bit tricky to tame a reindeer. Give it a try now, you should have better luck!
    </p>
    <p>
      <u>Building</u>
      <br>- Wearing the Thinking Hat and being in build mode always go together now.
    </p>
    <p>
      <u>Frostburgh</u>
      <br>- The Frostburg achievement called ‘Frostburgh Achiever 2’ in your passport can now be completed. For new players, all achievements you complete now count. For players who have already completed 10 missions in Frostburgh, but still don’t have the Frostburgh Achiever 2 achievement, a new mission to smash 5 lamp posts has been added, which, when completed, will give you the achievement.
    </p>
    <p>
      <u>Racing</u>
      <br>- After completing the first 2 race missions racing trainer Velocity Lane gives you at the Race Place in Nimbus Station, you will have access to Peppermint Lane’s race missions in Frostburgh.
    </p>
    <h4>December 1 2010 (v.1.2.20)</h4>
    <p>
      <u>Frostburgh is Here!</u>
      <br> - The Frostburgh zone is now active. The zone includes new Missions, Daily Missions and Achievements to complete for items and Candy Canes. Spend your Candy Canes at Hansel Tinsel’s shop to buy even more holiday goodies. New items include 31 new Holiday Reward Models, 4 new Consumables and 28 new Accessories. There’s a new Reindeer Pet to tame. Also, a Space Blizzard has blown over the Vertigo Loop Racetrack – brave the icy conditions and unlock 2 new holiday-themed racecars! Finally, players can now toss snowballs at each other and spread the Frostivus spirit!
    </p>
    <p>
      <u>Return to Venture Explorer</u>
      <br> - Players can now return to the Venture Explorer to assist the Nexus Force in retrieving vital information before the Maelstrom destroys the ship. This single player instance includes new Missions, Daily Missions and new items. It’s also a great way to return to the Venture Explorer and complete any Achievements you may have missed the first time through. Speak to Sky Lane in Avant Gardens after you have joined a Faction and completed her other Missions.
    </p>
    <p>
      <u>More Backpack Space!!!</u>
      <br> - All Minifigures will get a special email from Dr. Overbuild which unlocks 20 more Backpack slots! In addition, 5 new missions have been introduced around the Universe - complete these to unlock even more Backpack space! Speak to Beck Strongheart and Sky Lane in Avant Gardens and Kenjin the Wise in Forbidden Valley. Finally, the cost of Blue Imaginite has been dropped from 1000 coins to 500 coins, which will make Rad Eccles’ Backpack Mission chain easier to complete.
    </p>
    <p>
      <u>Survival Update</u>
      <br> - Gameplay in the Avant Gardens Survival instance has been updated so that the event is now more challenging, competitive and less prone to exploits. Because of this change, the Survival Leaderboards will be reset soon.
    </p>
    <p>
      <u>Racing</u>
      <br> - Car-to-car collisions will no longer cause anyone to explode.
    </p>
    <p>
      <u>Other Changes</u>
      <br> - Several User Interface elements have been updated and changed.
      <br> - The transition map screen has been updated.
      <br> - All items now feature a new rarity UI display. The bigger the diamond, the rarer the item.
      <br> - Dragon Bosses in Forbidden Valley have an updated Health Bar.
      <br> - Lots of miscellaneous fixes throughout the game.
    </p>
  </div>
</template>
